import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")!
  const _component_CustomCard = _resolveComponent("CustomCard")!
  const _component_CardScroll = _resolveComponent("CardScroll")!
  const _component_FlushBorders = _resolveComponent("FlushBorders")!
  const _component_ResetSidePaddings = _resolveComponent("ResetSidePaddings")!
  const _component_CardBorder = _resolveComponent("CardBorder")!
  const _component_CardScretch = _resolveComponent("CardScretch")!
  const _component_UtilityClasses = _resolveComponent("UtilityClasses")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_CustomCard),
      _createVNode(_component_CardScroll),
      _createVNode(_component_FlushBorders),
      _createVNode(_component_ResetSidePaddings),
      _createVNode(_component_CardBorder),
      _createVNode(_component_CardScretch),
      _createVNode(_component_UtilityClasses)
    ])
  ]))
}